<template>
    <div class="official-wrapper">
        <div class="content">
            <div class="official-box" v-if="false">
                <div class="official-title">授权公众号</div>
                <div class="official-p">目前仅支持授权1个公众号，如有更多需求，请联系客服</div>

                <div class="btn-box">
                    <div class="authorization-btn" @click="authorization">立即授权</div>
                    
                    <!-- <div class="operation-btn">操作教程</div> -->
                </div>
            </div>

            <div class="official-accounts-wrap">
                <div class="official-accounts-title">
                    <div class="-title-text">授权公众号</div>

                    <div class="btn-box">
                        <div class="authorization-btn" @click="authorization">立即授权</div>
                        
                        <!-- <div class="operation-btn">操作教程</div> -->
                    </div>
                </div>

                <div class="table">
                    <el-table :data="situationData" style="width: 100%;" height="100%" :empty-text="empty">
                        <el-table-column fixed prop="head_img" label="公众号头像" width="160" align="center">
                            <template slot-scope="scope">
                                <el-avatar :src="scope.row.head_img"></el-avatar>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nick_name" label="名称" width="290" align="center"></el-table-column>
                        <el-table-column prop="user_name" label="原始ID" width="290" align="center"></el-table-column>
                        <el-table-column prop="appid" label="AppID" width="200" align="center"></el-table-column>
                        <el-table-column label="类型" width="240" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.service_type.id == 1">服务号</div>
                                <div v-if="scope.row.service_type.id == 2">公众号</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="授权时间" width="180" align="center">
                            <template slot-scope="scope">
                                <div>{{scope.row.create_time | dateFormat}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" prop="id" label="操作" width="180" align="center">
                            <template slot-scope="scope">
                                <div class="regulate-btn" @click="linkReg(scope.row.appid, scope.row.nick_name, scope.row.head_img)">管理</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="paging-item" v-if="situationData.length > 0">
                    <el-pagination background @size-change="handleSizeChange"
                     @current-change="handleCurrentChange" :current-page.sync="currentPage"
                     layout="total, prev, pager, next, jumper" :total="currentPage">
                    </el-pagination>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import global from '@/assets/js/global';
    export default {
        components: {},
        data() {
            return {
                page: 1,
                maxPage: 15,
                situationData: [],
                currentPage: 0,
                signalTotal: '',
                empty: '暂无授权公众号'

            }
        },
        created() {
            this.attentionToReply();
        },
        methods: {
            // 微信公众号授权
            authorization() {
                axios.get(global.wechatURL + "third/get_pre_auth_code?appid=wxe71d642d7dad2367")
                .then(res => {
                    if(res.data.status == 200) {
                        var preauthcode = res.data.data;
                        if(preauthcode) {
                            window.open('https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=wxe71d642d7dad2367&pre_auth_code=' + preauthcode + '&redirect_uri=https://admin.ldydtech.com/&auth_type=3', '_blank');
                        }
                    } else {
                        this.$message({ showClose: true, message: '授权失败!', type: 'error' })
                    }
                });
            },
            // 公众号列表
            attentionToReply() {
                axios.get(global.wechatURL + "third/get_authorizer_list", {
                    params: {
                        appid: 'wxe71d642d7dad2367',
                        page: this.page,
                        max_page: this.maxPage
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var List = res.data.data;
                        this.situationData = List;
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error' })
                    }
                });
            },

            // 
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            // 
            linkReg(appid, nick_name, head_img) {
                this.$router.push({
                    path: '/publicAccountManagement',
                    query: {
                        appid: appid,
                        nickName: nick_name,
                        headImg: head_img
                    }
                })
            }

        }
    }
</script>

<style lang="less" scoped>
    .content {
        padding: 0 25px;
    }

    .official-box {
        padding: 20px 30px;
        background: #fff;
        box-shadow: 0 0 9px #e7e7e7;
        .official-title {
            font-size: 24px;
            font-weight: bold;
            color: #252530;
        }
        .official-p {
            font-size: 16px;
            color: #252530;
            line-height: 55px;
        }
    }
    .btn-box {
        display: flex;
        align-items: center;
        .authorization-btn {
            margin-right: 15px;
            width: 112px;
            height: 36px;
            background: #FF6056;
            border: 1px solid #FF6056;
            border-radius: 6px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
        }
        .operation-btn {
            width: 112px;
            height: 36px;
            background: #FFFFFF;
            border: 1px solid #FF6056;
            border-radius: 6px;
            font-size: 14px;
            color: #FF6056;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
        }
    }

    .official-accounts-wrap {
        .official-accounts-title {
            padding: 15px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .-title-text {
                font-size: 24px;
                font-weight: bold;
                color: #252530;
            }
        }
    }

    .table {
        padding-top: 20px;
        width: 100%;
        height: calc(100vh - 265px);
    }
    .table /deep/ .el-table th.el-table__cell {
        background: #F6F7FB;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .regulate-btn {
        font-size: 14px;
        color: #1A54EC;
        cursor: pointer;
    }
    
    .paging-item {
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

</style>
